import { useRouter } from 'next/navigation';
import React from 'react';

import style from './LoginForm.module.css';

import { LoginWithGoogle } from '../LoginWithGoogle/LoginWithGoogle';

import { useBackendHost } from '@/api';

export default function LoginForm() {
  const router = useRouter();
  const host = useBackendHost();

  const login = () => {
    router.push(host + '/auth/login');
  };

  return (
    <div className={style.mainContainer}>
      <div className={style.leftGrid}>
        <div className={style.leftGridContent}>
          <img
            src="./wix-assets/logo.png"
            alt="logo"
            className={style.companyLogo}
          />
          <div className={style.loginSubtitle}>Log in</div>
          <div className={style.loginContent}>
            Welcome! Connect with your Google account <br />
            to access your exeutive planning and <br />
            execution dashboard
          </div>
          <div className={style.formContainer}>
            <LoginWithGoogle onClick={login} />
          </div>
          <div className={style.footer}>made with ❤️ by theGist</div>
        </div>
      </div>
      <div className={style.rightGrid}>
        <div className={style.rightGridContent}>
          <div className={style.rightGridHeader}>
            {'"The Wix Vision - Where anyone can create their dreams online"'}
          </div>
          <div className={style.rightGridSubHeader}>
            <span>— Avishay Avrahami</span>
            <br />
            CEO, Wix
          </div>
          <div className={style.bannerAnchor}>
            <img
              className={style.bottomBanner}
              src="./images/login-bottom-banner.png"
              alt="bottom-banner"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
