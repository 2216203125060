import React, { FC } from 'react';

import style from './LoginWithGoogle.module.css';

import Google from '@/icons/components/Google';

interface LoginWithGoogleProps {
  onClick: () => void;
}

export const LoginWithGoogle: FC<LoginWithGoogleProps> = ({ onClick }) => {
  return (
    <div className={style.googleButtonContainer}>
      <button onClick={onClick} className={style.googleButton}>
        <Google />
        Sign in with Google
      </button>
    </div>
  );
};
